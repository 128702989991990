<template>
  <div>
    <p class="title">Cadastrar nova Senha</p>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <ValidationProvider
          name="password"
          rules="required|min:6"
          v-slot="{ errors }"
        >
          <div class="field">
            <div class="control">
              <input
                v-model="user.password"
                class="input"
                type="password"
                placeholder="Nova senha"
                required
              />
            </div>
            <p class="help is-danger">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>

        <div class="level">
          <div class="field is-grouped">
            <div class="control">
              <button :disabled="request || invalid" class="button is-warning">
                Cadastrar
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
extend("min", {
  ...min,
  message: "Mínimo 6 caracteres",
});

export default {
  name: "ResetPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return { user: {}, request: false, token: "" };
  },
  created() {
    this.request = false;
    this.token = this.$route.params.token;
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.request = true;
        this.$http
          .post(`${process.env.VUE_APP_URI}auth/clinic/reset_password`, {
            password: this.user.password,
            token: this.token,
          })
          .then(() => {
            this.$buefy.toast.open({
              message: "Senha Alterada",
              position: "is-top-right",
              type: "is-success",
            });
            this.$router.push("/auth/login");
          })
          .catch((err) => {
            switch (err.response.status) {
              case 404:
                this.$buefy.toast.open({
                  message: "Link inválido",
                  position: "is-top-right",
                  type: "is-danger",
                });
                break;
            }
          });
      });
    },
  },
};
</script>
